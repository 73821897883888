<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn
          icon
          dark
          @click="
            closeDialog;
            dialog = false;
          "
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Preview Student Record </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                dark
                @click="saveStudentChanges"
                color="success lighten-3"
                x-large
                icon
              >
                <v-icon>mdi-content-save-move-outline</v-icon>
              </v-btn>
            </template>
            <span>Click to Save Changes</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                dark
                color="error"
                @click="deleteStudentBtn(id)"
              >
                <v-icon x-large>mdi-delete-circle</v-icon>
              </v-btn>
            </template>
            <span>Click to Delete Student</span>
          </v-tooltip>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-form ref="studentPreviewForm">
          <v-row>
            <v-col
              cols="12"
              class="headline text-center font-weight-bold"
              lg="12"
            >
              {{ `INDEX NUMBER: ${indexNumber} ` }}
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="12" lg="4">
              <v-text-field
                hide-details="auto"
                filled
                disabled
                label="Index Number"
                v-model="indexNumber"
              ></v-text-field>
              <v-select
                hide-details="auto"
                :menu-props="{ down: true, offsetY: true }"
                filled
                label="Title"
                :rules="[rules.required]"
                :items="titles"
                v-model="title"
              >
              </v-select>
              <v-select
                hide-details="auto"
                :menu-props="{ down: true, offsetY: true }"
                :items="genders"
                v-model="gender"
                filled
                label="Gender"
                :rules="[rules.required]"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Loading
                      <strong>Genders...</strong>
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
              <v-text-field
                hide-details="auto"
                filled
                label="Other Names"
                v-model="otherName"
                :rules="[rules.required]"
              ></v-text-field>

              <v-text-field
                hide-details="auto"
                filled
                label="Last Name"
                v-model="lastName"
                :rules="[rules.required]"
              ></v-text-field>
              <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                ref="dobDialog"
                v-model="menuDob"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dob"
                    label="Date of Birth"
                    readonly
                    filled
                    hide-details="auto"
                    v-bind="attrs"
                    v-on="on"
                    :rules="[rules.required]"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dob" @input="menuDob = false">
                </v-date-picker>
              </v-menu>
              <!-- <v-text-field
                filled
                label="NHIS"
                v-model="idCard"
                hide-details="auto"
              ></v-text-field> -->
              <v-select
                :menu-props="{ down: true, offsetY: true }"
                label="Nationality"
                :items="nationalities"
                filled
                v-model="nationality"
                :rules="[rules.required]"
                hide-details="auto"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Loading
                      <strong>Nationalities...</strong>
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>

              <v-text-field
                filled
                label="Mobile Number"
                hide-details="auto"
                :rules="[rules.required, rules.mobilenumber]"
                v-model="mobilenumber"
              ></v-text-field>
              <v-text-field
                filled
                label="Personal Email"
                :rules="[rules.required, rules.email]"
                v-model="email"
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="4">
              <v-autocomplete
                disabled
                :loading="loadProgram"
                @change="selectedProgram"
                :items="itemsProgram"
                hide-selected
                item-text="program_name_certificate"
                item-value="id"
                label="Search Program"
                filled
                :rules="[rules.required]"
                v-model="program"
                hide-details="auto"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Search
                      <strong>Program...</strong>
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>

              <v-select
                :menu-props="{ down: true, offsetY: true }"
                filled
                label="Intake"
                v-model="intake"
                hide-selected
                :loading="isintakeLoaded"
                :items="basket_intake"
                item-text="intake"
                item-value="id"
                :rules="[rules.required]"
                hide-details="auto"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Loading
                      <strong>Intakes...</strong>
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
              <v-select
                v-model="level"
                :items="basket_level"
                hide-details="auto"
                :loading="loadLevel"
                @click="fetchProgramLevel"
                item-value="id"
                item-text="level"
                hide-selected
                filled
                label="Start Level"
                :rules="[rules.required]"
                :menu-props="{ down: true, offsetY: true }"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Search
                      <strong>Levels...</strong>
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
              <v-select
                :menu-props="{ down: true, offsetY: true }"
                :items="basket_session"
                hide-details="auto"
                item-text="session"
                item-value="id"
                :loading="issessionLoaded"
                hide-selected
                filled
                label="Session"
                v-model="session"
                :rules="[rules.required]"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Loading
                      <strong>Sessions...</strong>
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
              <v-select
                :menu-props="{ down: true, offsetY: true }"
                v-model="campus"
                item-value="id"
                item-text="campus_name"
                hide-details="auto"
                :items="basket_campus"
                :loading="iscampusLoaded"
                label="Campus"
                hide-selected
                filled
                :rules="[rules.required]"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Loading
                      <strong>Campus(es)...</strong>
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
              <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                v-model="modaladmissionDate"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="admissionDate"
                    label="Admission Date"
                    hide-details="auto"
                    filled
                    v-bind="attrs"
                    v-on="on"
                    :rules="[rules.required]"
                  ></v-text-field>
                </template>
                <v-date-picker type="month" v-model="admissionDate" scrollable>
                </v-date-picker>
              </v-menu>
              <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                ref="completiondateDialog"
                v-model="modalcompletionDate"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    disabled
                    v-model="completionDate"
                    label="Completion Date"
                    hide-details="auto"
                    filled
                    v-bind="attrs"
                    v-on="on"
                    :rules="[rules.required]"
                  ></v-text-field>
                </template>
                <v-date-picker type="month" v-model="completionDate" scrollable>
                </v-date-picker>
              </v-menu>
              <v-select
                :menu-props="{ down: true, offsetY: true }"
                label="Student Type"
                v-model="selectedstudentType"
                :items="studentTypes"
                hide-details="auto"
                filled
                :rules="[rules.required]"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Loading
                      <strong>Student Type(s)...</strong>
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
              <v-text-field
                filled
                label="Hall"
                v-model="hall"
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="4">
              <v-text-field
                filled
                label="Full name"
                v-model="emergencyName"
                :rules="[rules.required]"
                hide-details="auto"
              ></v-text-field>
              <v-text-field
                v-model="emergencyTelephone"
                label="Telephone"
                filled
                :rules="[rules.required, rules.mobilenumber]"
                hide-details="auto"
              ></v-text-field>
              <v-text-field
                v-model="emergencyEmail"
                label="Email"
                filled
                :rules="[rules.required, rules.email]"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-overlay :absolute="absolute" :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <Error v-if="deleteResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Error>
  </v-dialog>
</template>

<script>
  import { getCurrentInstance, reactive, toRefs, watch } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import Error from "@/components/Error/Error";
  import moment from "moment";
  export default {
    components: { Error },
    emits: ["close-dialog"],
    props: ["previewstudentDialog"],
    setup(props, context) {
      const vm = getCurrentInstance()
      const {
        deleteStudentRecord,
        getProgramDepartment,
        updateStudentRecord,
        signOut,
      } = useActions([
        "deleteStudentRecord",
        "getProgramDepartment",
        "updateStudentRecord",
        "signOut",
      ]);

      const {
        getters_program,
        getters_level,
        getters_intake,
        getters_campus,
        getters_session,
        user,
        getters_abilities,
        getters_program_department,
        getters_saved_student,
      } = useGetters([
        "getters_program",
        "getters_level",
        "getters_intake",
        "getters_campus",
        "getters_session",
        "user",
        "isstudentstatusLoaded",
        "getters_abilities",
        "getters_program_department",
        "getters_saved_student",
      ]);

      let studentForm = reactive({
        absolute: true,
        overlay: false,
        dialog: props.previewstudentDialog,
        indexNumber: null,
        loadDepartment: false,
        disabledDepartment: true,
        department_id: null,
        itemsDepartment: [],
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        deleteResponse: false,
        checkindexNumber: false,
        basket_calendar: [],
        calendar_id: null,
        menuDob: false,
        modaladmissionDate: false,
        modalcompletionDate: false,
        completionDialog: false,
        studentTypes: ["Regular", "Matured"],
        dob: "",
        admissionDate: "",
        completionDate: "",
        selectedstudentType: "",
        intake: "",
        session: "",
        program: "",
        campus: "",
        nationality: "",
        error_msg: "",
        nationalities: [
          "Ghanaian",
          "Nigerian",
          "Burkinabe",
          "Cameroonian",
          "Togolese",
          "Beninese",
        ],
        hall: "",
        level: "",
        title: "",
        gender: "",
        otherName: "",
        middleName: "",
        lastName: "",
        // idCard: "",
        emergencyName: "",
        emergencyTelephone: "",
        emergencyEmail: "",
        genders: ["Male", "Female", "Prefer Not To Say"],
        titles: ["Mr.", "Miss", "Mrs.", "Dr."],
        email: "",
        rules: {
          required: (value) => !!value || "Required.",
          email: (value) => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          mobilenumber: (value) => {
            const pattern = /^[0]?\d{10}$/;
            return pattern.test(value) || "Invalid mobile number.";
          },
        },
        mobilenumber: "",
        loadProgram: false,
        disabledLevel: true,
        loadStatus: false,
        itemsStatus: [],
        itemsProgram: [],
        lastStep: 3,
        iscalendarLoaded: false,
        status: "",
        steps: [
          {
            name: "Personal Information",
            valid: true,
          },
          { name: "School Information", valid: true },
          { name: "Emergency Information", valid: false },
        ],
        stepForm: [],
        valid: false,
        basket_semester: [],
        basket_level: [],
        basket_intake: [],
        basket_campus: [],
        basket_session: [],
        isintakeLoaded: false,
        issessionLoaded: false,
        iscampusLoaded: false,
        loadLevel: false,
        getprogInfo: null,
        id: null,
      });

      const {
        msgHeader,
        msgBody,
        msgIcon,
        deleteResponse,
        overlay,
        indexNumber,
        dialog,
        disabledDepartment,
        loadDepartment,
        department_id,
        itemsDepartment,
        disabledLevel,
        calendar_id,
        email,
        itemsProgram,
        loadLevel,
        basket_level,
        basket_campus,
        basket_session,
        basket_intake,
        dob,
        admissionDate,
        completionDate,
        selectedstudentType,
        intake,
        session,
        program,
        campus,
        nationality,
        hall,
        level,
        title,
        gender,
        otherName,
        middleName,
        lastName,
        // idCard,
        emergencyName,
        emergencyTelephone,
        emergencyEmail,
        mobilenumber,
        basket_calendar,
        status,
        getprogInfo,
        id,
      } = toRefs(studentForm);

      const selectedProgram = (i) => {
        disabledLevel.value = true;
        getprogInfo.value = getters_program.value.find((item) => item.id === i);

        loadDepartment.value = true;
        loadLevel.value = true;

        getProgramDepartment(i).then(() => {
          itemsDepartment.value = getters_program_department.value;
          loadDepartment.value = false;
          disabledDepartment.value = false;
        });

        level.value = "";
        if (getprogInfo.value.program_state) {
          loadLevel.value = false;
          basket_level.value = getters_level.value.filter(
            (item) => item.level === 300
          );
        } else {
          basket_level.value = getters_level.value.filter(
            (item) => item.level <= 200
          );
          loadLevel.value = false;
        }
      };

      const fetchProgramLevel = () => {
        const userProgram = getters_program.value.find(
          (item) => item.id === program.value
        );
        basket_level.value =
          userProgram.program_state === false
            ? getters_level.value.filter(
                (level) => level.level !== 300 && level.level !== 400
              )
            : getters_level.value.filter(
                (level) =>
                  level.level !== 100 &&
                  level.level !== 200 &&
                  level.level !== 400
              );
      };

      const saveStudentChanges = () => {
        let v = vm.proxy.$refs.studentPreviewForm.validate();
        if (v) {
          let basket = {
            id: id.value,
            title: title.value,
            gender: gender.value,
            other_name: otherName.value,
            last_name: lastName.value,
            dob: dob.value,
            // id_card: idCard.value,
            nationality: nationality.value,
            mobile_number: mobilenumber.value,
            email: email.value,
            department_id: department_id.value,
            program_id: program.value,
            intake_id: intake.value,
            level_id: level.value,
            session_id: session.value,
            campus_id: campus.value,
            admission_date: admissionDate.value,
            completion_date: completionDate.value,
            student_type: selectedstudentType.value,
            hall: hall.value,
            emergency_name: emergencyName.value,
            emergency_mobile_number: emergencyTelephone.value,
            emergency_email: emergencyEmail.value,
            calendar_id: calendar_id.value,
            staff_id: user.value.id,
            student_status_id: status.value,
          };
          if (vm.proxy.refs.studentPreviewForm.validate()) {
            overlay.value = true;
            updateStudentRecord(basket)
              .then(() => {
                overlay.value = false;
                vm.proxy.refs.studentPreviewForm.reset();
                dialog.value = false;
                context.emit("updatedStudent", true);
              })
              .catch((e) => {
                overlay.value = false;
                deleteResponse.value = true;
                msgHeader.value = "Error";
                msgIcon.value = "mdi-close-circle";
                if (e.response.status === 403) {
                  msgBody.value = e.response.data.message;
                } else if (e.response.status === 423) {
                  msgBody.value = e.response.data.message;
                  signOut().then(() => {
                    vm.proxy.$router.replace({
                      name: "Login",
                    });
                  });
                }
              });
          }
        }
      };

      const deleteStudentBtn = (i) => {
        overlay.value = true;
        deleteStudentRecord(i).then(() => {
          overlay.value = false;
          vm.proxy.$refs.studentPreviewForm.reset();
          dialog.value = false;
          context.emit("deletedStudent", true);
        });
      };

      id.value = getters_saved_student.value.id;
      title.value = getters_saved_student.value.title;
      otherName.value = getters_saved_student.value.other_name;
      middleName.value = getters_saved_student.value.middle_name;
      lastName.value = getters_saved_student.value.last_name;
      dob.value = getters_saved_student.value.dob;
      gender.value = getters_saved_student.value.gender;
      mobilenumber.value = getters_saved_student.value.mobile_number;
      email.value = getters_saved_student.value.email;
      // idCard.value = getters_saved_student.value.id_card;
      nationality.value = getters_saved_student.value.nationality;
      indexNumber.value = getters_saved_student.value.index_number;
      emergencyName.value = getters_saved_student.value.emergency_name;
      emergencyTelephone.value =
        getters_saved_student.value.emergency_mobile_number;
      emergencyEmail.value = getters_saved_student.value.emergency_email;
      admissionDate.value = getters_saved_student.value.admission_date;
      completionDate.value = getters_saved_student.value.completion_date;
      hall.value = getters_saved_student.value.hall;
      selectedstudentType.value = getters_saved_student.value.student_type;

      program.value = getters_saved_student.value.program_id;
      intake.value = getters_saved_student.value.intake_id;
      session.value = getters_saved_student.value.session_id;
      campus.value = getters_saved_student.value.campus_id;
      level.value = getters_saved_student.value.level_id;

      itemsProgram.value = getters_program.value;
      basket_session.value = getters_session.value;

      getprogInfo.value = itemsProgram.value.find(
        (item) => item.id === program.value
      );

      fetchProgramLevel();

      if (getters_abilities.value.includes("all_campus_access")) {
        basket_campus.value = getters_campus.value;
      } else {
        basket_campus.value = getters_campus.value.filter(
          (campus) => campus.id === parseInt(user.value.campus[0].id)
        );
      }

      watch(
        () => admissionDate.value,
        () => {
          if (admissionDate.value) {
            const date = new Date(admissionDate.value);
            const newDate = new Date(
              date.setFullYear(date.getFullYear() + getprogInfo.value.duration)
            );

            completionDate.value = moment(newDate).format("YYYY-MM");
          }
        }
      );

      basket_intake.value = getters_intake.value.filter(
        (intake) => intake.intake.toLowerCase() != "all"
      );

      const closeDialog = () => {
        context.emit("close-dialog", (dialog.value = false));
      };

      return {
        ...toRefs(studentForm),
        saveStudentChanges,
        basket_calendar,
        selectedProgram,
        deleteStudentBtn,
        fetchProgramLevel,
        closeDialog,
      };
    },
  };
</script>
