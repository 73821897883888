<template>
  <v-container fluid>
    <v-card outlined min-width="500" class="mx-auto">
      <v-card-title primary-title class="primary--text text-uppercase">
        Review and Save New User
      </v-card-title>
      <v-divider></v-divider>
      <v-virtual-scroll
        :bench="benched"
        :items="name"
        height="200"
        item-height="50"
      >
        <template v-slot:default="{ item }">
          <v-list-item :key="item.page">
            <v-list-item-content class="body-1">
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn depressed small @click="viewSteppter(item.page)">
                View Details

                <v-icon color="primary" right> mdi-open-in-new </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>
        </template>
      </v-virtual-scroll>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          color="success"
          :disabled="!getters_abilities.includes('create_student_access')"
          @click="send"
          class="font-weight-bold"
        >
          Save User
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
  import { defineComponent, reactive, toRefs } from "vue";
  // import Documents from "@/components/Student/Create/Documents";
  import Personal from "@/components/Student/Create/Personal";
  import SchoolInformation from "@/components/Student/Create/SchoolInformation";
  import EmergencyInformation from "@/components/Student/Create/EmergencyInformation";
  import { useGetters } from "vuex-composition-helpers";

  export default defineComponent({
    components: {
      // Documents,
      Personal,
      SchoolInformation,
      EmergencyInformation,
    },
    setup(props, context) {
      const { getters_abilities } = useGetters(["getters_abilities"]);
      const resultsCard = reactive({
        name: [
          { name: "Personal", page: 1 },
          { name: "School Information", page: 2 },
          { name: "Emergency Information", page: 3 },
          { name: "Documents", page: 4 },
        ],
        benched: 1,
        tab: null,
      });

      const viewSteppter = (page) => context.emit("goto", page);

      const send = () => context.emit("send", true);

      return {
        ...toRefs(resultsCard),
        viewSteppter,
        send,
        getters_abilities,
      };
    },
  });
</script>
