<template>
  <v-container fluid>
    <v-card flat>
      <v-card-title class="text-h3 d-flex justify-center">
        {{ getters_saved_student.index_number_title_name }}
      </v-card-title>
    </v-card>
  </v-container>
</template>
<script>
  import { defineComponent } from "vue";
  import { useGetters } from "vuex-composition-helpers";

  export default defineComponent({
    setup() {
      const { getters_saved_student } = useGetters(["getters_saved_student"]);

      return {
        getters_saved_student,
      };
    },
  });
</script>
