<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">ADD NEW </span> STUDENT
    </PageHeader>

    <v-stepper v-model="curr">
      <v-stepper-header class="overflow-x-auto">
        <v-stepper-step
          v-for="(step, index) in steps"
          :key="index"
          :complete="stepComplete(index + 1)"
          :step="index + 1"
          :rules="[(value) => !!step.valid]"
        >
          {{ step.name }}
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-content v-for="(step, n) in steps" :step="n + 1" :key="n">
        <component
          @goto="goto"
          @send="send"
          @page="data"
          :key="reset"
          :is="step.component"
        />

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            class="font-weight-bold"
            v-show="curr > 1"
            @click="curr = n"
          >
            Back
          </v-btn>
        </v-card-actions>
      </v-stepper-content>
    </v-stepper>

    <Response style="z-index: 999" v-if="actionResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>

    <v-overlay absolute :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-dialog
      v-model="dialog"
      fullscreen
      :overlay="false"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Saved User Details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn x-large dark icon @click="deleteUserBtn">
              <v-icon>mdi-account-remove-outline</v-icon>
            </v-btn>
            <v-btn dark text @click="dialog = false"> Close </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <saved-user />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { defineComponent, getCurrentInstance, provide, reactive, toRefs } from "vue";
  import PageHeader from "@/components/slots/PageHeader";
  import PreviewStudent from "@/components/Student/PreviewStudent";
  import Personal from "@/components/Student/Create/Personal";
  import SchoolInformation from "@/components/Student/Create/SchoolInformation";
  import EmergencyInformation from "@/components/Student/Create/EmergencyInformation";
  import Documents from "@/components/Student/Create/Documents";
  import Preview from "@/components/Student/Create/Preview";
  import SavedUser from "@/components/Student/SavedUser";
  import Response from "@/components/ActionResponse/Response";
  import Api from "@/apis/Api";
  import { useActions, useGetters } from "vuex-composition-helpers";

  export default defineComponent({
    components: {
      PageHeader,
      PreviewStudent,
      Response,
      Personal,
      SchoolInformation,
      EmergencyInformation,
      Documents,
      Preview,
      SavedUser,
    },

    setup() {
      const vm = getCurrentInstance()
      const { getters_saved_student } = useGetters(["getters_saved_student"]);
      const { deleteStudentRecord, signOut } = useActions([
        "deleteStudentRecord",
        "signOut",
      ]);
      const studentForm = reactive({
        curr: 1,
        grade: null,
        actionResponse: false,
        absolute: true,
        overlay: false,
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        deleteResponse: false,
        formData: new FormData(),
        status: "",
        steps: [
          { name: "Personal Information", component: "Personal", valid: true },
          {
            name: "School Information",
            component: "SchoolInformation",
            valid: true,
          },
          {
            name: "Emergency Information",
            component: "EmergencyInformation",
            valid: true,
          },
          {
            name: "Documents",
            component: "Documents",
            valid: true,
          },
          {
            name: "Preview",
            component: "Preview",
            valid: true,
          },
        ],
        valid: false,
        progress: 0,
        dialog: false,
        reset: 0,
        color: null,
      });

      const {
        curr,
        formData,
        steps,
        progress,
        overlay,
        actionResponse,
        msgHeader,
        msgBody,
        msgIcon,
        color,
        dialog,
        reset,
      } = toRefs(studentForm);

      const store = vm.proxy.$store;

      const stepComplete = (step) => {
        return curr.value > step;
      };

      const data = (response) => {
        const { bool, page, data, name } = response;

        if (bool) {
          steps.value[page - 1].valid = true;
          curr.value = page + 1;

          if (data.image) {
            formData.value.append("file", data.image);
            delete data.image;
            formData.value.append(name, JSON.stringify(data));
          } else if (data.files) {
            for (let file of data.files) {
              let p = 0;
              formData.value.append("documents[" + p++ + "]", file);
            }
            // data.files.forEach((file, i) =>
            // );
          } else formData.value.append(name, JSON.stringify(data));
          return;
        }
        steps.value[page - 1].valid = false;
      };

      const goto = (step) => (curr.value = step);

      const send = async (bool) => {
        if (bool) {
          progress.value = 0;
          overlay.value = true;
          actionResponse.value = false;
          await Api()
            .post(
              "/savestudent",
              formData.value,
              /* {
                                    headers: { "Content-Type": "multipart/form-data" },
                                  }, */
              {
                onUploadProgress: (e) => {
                  progress.value = Math.round((e.loaded * 100) / e.total);
                },
              }
            )
            .then((res) => {
              store.commit("SAVE_STUDENT", res.data.data);
              dialog.value = true;
              curr.value = 1;
              reset.value++;
              color.value = "success";
            })

            .catch((e) => {
              actionResponse.value = true;
              msgHeader.value = "Error";
              msgIcon.value = "mdi-close-circle";
              color.value = "error";

              switch (e.response.status) {
                case 423:
                  msgBody.value = e.response.data.message;
                  signOut().then(() => {
                    vm.proxy.$router.replace({
                      name: "Login",
                    });
                  });
                  break;

                case 403:
                  msgBody.value = e.response.data.message;
                  break;

                default:
                  msgBody.value =
                    "Try Again Later Or Call The System Administrator";
                  break;
              }
            })
            .finally(() => (overlay.value = false));
        }
      };

      provide("color", color);

      const deleteUserBtn = () => {
        overlay.value = true;
        actionResponse.value = false;
        deleteStudentRecord(getters_saved_student.value.id)
          .then(() => {
            overlay.value = false;
            actionResponse.value = false;
            msgHeader.value = "Success";
            msgBody.value = "User is successfully deleted";
            msgIcon.value = "mdi-check-circle";
            color.value = "success";
          })
          .catch((err) => {
            console.log(err);
            actionResponse.value = true;
            msgHeader.value = "Error";
            msgBody.value = "Try Again Later or Call the System Administrator";
            msgIcon.value = "mdi-close-circle";
            color.value = "error";
          })
          .finally(() => (dialog.value = false));
      };

      return {
        ...toRefs(studentForm),
        getters_saved_student,
        stepComplete,
        data,
        goto,
        send,
        deleteUserBtn,
      };
    },
  });
</script>
